
import {
    PropType,
    reactive,
    ref,
    SetupContext,

    defineComponent,
} from "vue";
import { CalendarOutlined, SmileOutlined } from '@ant-design/icons-vue';

import { ReasonForMonitoringEnum, MonitoringStageEnum, MonitoringResultEnum, ODPMonitorRecordRequest } from "@/api-client/client";
import { _Client } from "@/api-client";
import { message, Form} from "ant-design-vue";


const useForm = Form.useForm;
interface AddExaminerModel {
    examinerId: string | undefined;
    examinerName: string | undefined;
    examinerNo: string | undefined;
}
export default defineComponent({
    props: {
        examinerList: {
            type: Array as PropType<AddExaminerModel[]>,
            default: () => [],
        },
    },
    components: {
        CalendarOutlined,
        SmileOutlined
    },
    setup(props: { [key: string]: any }, context: SetupContext) {
        const reasonForMonitoringEnum = ReasonForMonitoringEnum;
        const monitoringResultEnum = MonitoringResultEnum;
        const monitoringStageEnum = MonitoringStageEnum;
        const examinerList = props.examinerList;
        const odpMonitorRecordModel = ref(ODPMonitorRecordRequest.fromJS({ examinerId: "", monitoringResult: MonitoringResultEnum.Standard, monitorDate: null }))
        const monitorDate = ref<string | null>(null);
        const rules = reactive({
            monitoringDate: [
                {
                    required: true,
                    message: 'Please select Date of Monitoring',

                },
            ],
            monitoringReason: [
                {
                    required: true,
                    message: 'Please select Reason for Monitoring',
                },
            ],
            monitoringStage: [
                {
                    required: true,
                    message: 'Please select Monitoring Stage',
                },
            ],
            monitoringResult: [
                {
                    required: true,
                    message: 'Monitoring Result',
                },
            ],
        });

        const saveLoading = ref<boolean>(false);
        const { validate, validateInfos } = useForm(odpMonitorRecordModel, rules)

        function close() {
            context.emit("close");
        }

        function getList() {
            context.emit("getList");
        }

        const addMonitorRecord = (() => {

            validate().then(async () => {
                saveLoading.value = true;
                    var requestList: ODPMonitorRecordRequest[] = [];
                    var monitor = odpMonitorRecordModel.value;
                    examinerList.forEach((examiner: AddExaminerModel) => {
                        let request = new ODPMonitorRecordRequest();
                        request.init({
                            examinerId: examiner.examinerId,
                            monitoringReason: monitor.monitoringReason,
                            monitoringResult: monitor.monitoringResult,
                            monitoringStage: monitor.monitoringStage,
                            monitoringDate: monitor.monitoringDate
                        });
                        requestList.push(request);
                    });
                    
                    var result = await _Client.monitorRecordsClient.addODPMonitorRecord(requestList);
                    if (result.isSuccessful) {
                        message.success('Operation Success.');
                        saveLoading.value = false;
                        close();
                        getList();
                    }
                    else {
                        saveLoading.value = false;
                        message.error(result.message);
                    }
            }).catch(error => {});
        })

        const changeMonitorDate = (val: any) => {
            if (val != null) {
                const date = val.format("YYYY-MM-DD")

                odpMonitorRecordModel.value.monitoringDate = date;
            }
            else {
                odpMonitorRecordModel.value.monitoringDate = undefined;
            }
        }

        // 处理关闭标签的事件
        const handleClose = (
            tagToRemove: { examinerId: string; examinerName: string },
            index: number
        ) => {
            // 从数组中移除标签
            examinerList.splice(index, 1);
        };



        return {
            close,
            examinerList,
            monitoringResultEnum,
            monitoringStageEnum,
            rules,
            odpMonitorRecordModel,
            validateInfos,
            monitorDate,
            saveLoading,
            reasonForMonitoringEnum,
            addMonitorRecord,
            changeMonitorDate,
            handleClose,
        };
    }
});


